<template>
  <v-card :height="135" flat>
    <v-card-text v-if="loaderMetric">
      <v-skeleton-loader transition="fade-transition" type="sentences" />
    </v-card-text>
    <v-hover v-slot:default="{hover}" open-delay="200" v-else>
      <v-card-text>

        <!-- draggable handler -->
        <v-icon v-if="draggable && hover" class="vue-draggable-handle vue-draggable-handle--icon" color="grey-500" size="30">mdi-drag-horizontal</v-icon>
        <!-- end draggable handler -->

        <!-- metric header -->
        <v-row align="center" justify="space-between" no-gutters>
          <v-col cols="9">
            <span class="d-inline-block body-2 grey-500--text font-weight-medium text-truncate" style="max-width:100%">{{$t(`metrics.${metric}`)}}</span>
          </v-col>
          <v-col class="text-right" v-if="hover">
            <v-tooltip color="white-0" max-width="280px" transition="scale-transition" top>
              <template v-slot:activator="{on, attrs}">
                <v-icon class="mt-n1" v-bind="attrs" v-on="on" small color="grey-500">mdi-information</v-icon>
              </template>
              <span class="d-block px-3 py-2">{{((config || {}).description || 'Sin descripción')}}</span>
            </v-tooltip>
            <v-btn class="ml-2 mt-n1" v-if="removable" @click="$emit('destroy', true)" icon x-small><v-icon color="grey-500" size="20">mdi-delete-outline</v-icon></v-btn>
          </v-col>
        </v-row>
        <!-- end metric header -->

        <!-- metric representation -->
        <v-row align="center" no-gutters>
          <v-col cols="12">
            <span class="headline" v-if="dataset.value === null">Sin datos</span>
            <span class="headline grey-500--text" v-else>{{dataset.value | number(account.base_currency.rounded)}}</span>
            <span class="ml-2 mt-1 subtitle-1 text--'blue-500" :class="{'body': (dataset.value.toString().length > 8)}" v-if="dataset.value">{{account.base_currency.id}}</span>
          </v-col>
          <v-col cols="12">
            <template v-if="dataset.comparative_percent !== 0 && (((config || {}).type || {}).kpi || {}).displayComparative">
              <span class="mr-2 body-2 text--'blue-500">{{dataset.comparative_value | number(account.base_currency.rounded)}} {{account.base_currency.id}}</span>
              <template v-if="dataset.indicator_symbol === 'up'">
                <span class="green--text text--lighten-1"><template v-if="dataset.comparative_percent > 0">+</template>{{dataset.comparative_percent}}%</span>
                <v-icon class="mt-n1 ml-1" color="green lighten-1" small>mdi-trending-up</v-icon>
              </template>
              <template v-else-if="dataset.indicator_symbol === 'down'">
                <span class="red--text text--lighten-1"><template v-if="dataset.comparative_percent > 0">+</template>{{dataset.comparative_percent}}%</span>
                <v-icon class="mt-n1 ml-1" color="red lighten-1" small>mdi-trending-down</v-icon>
              </template>
            </template>
            <template v-else>
              <span class="text--'blue-500">-</span>
            </template>
          </v-col>
          <v-col cols="12">
            <span class="d-block mt-1 text--'blue-500 caption" v-if="(((config || {}).type || {}).kpi || {}).displayComparative"><span class="grey-500--text font-weight-medium">{{$t(`api.${dataset.interval}`)}}</span> • período {{$t(`api.${dataset.comparative}`)}}</span>
          </v-col>
        </v-row>
        <!-- end metric representation -->

      </v-card-text>
    </v-hover>
  </v-card>
</template>
<script>
import MetricViewMixin from '@/mixins/MetricViewMixin'

export default {
  props: {
    draggable: {
      type: Boolean,
      required: false,
      default: () => (false)
    },
    metric: {
      type: String,
      required: true
    },
    interval: {
      type: String,
      required: true,
      default: () => ('monthly')
    },
    comparative: {
      type: String,
      required: false,
      default: () => ('this_v_last')
    },
    removable: {
      type: Boolean,
      required: false,
      default: () => (false)
    },
    settings: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  mixins: [
    MetricViewMixin
  ],
  data: () => ({
    dataset: {},
    config: {}
  }),
  created () {
    this.retrieveMetric()
  },
  watch: {
    comparative () {
      this.retrieveMetric()
    },
    interval () {
      this.retrieveMetric()
    },
    metric () {
      this.retrieveMetric()
    }
  },
  methods: {
    retrieveMetric () {
      const query = {
        interval: this.interval,
        comparative: this.comparative
      }

      this.loaderMetric = true

      this.$store.dispatch('analytics/GET', {
        resource: `metrics/${this.metric}/kpi`,
        query: Object.assign({}, query)
      })
      .then((response) => {
        this.dataset = response.data
        this.config = this.getMetric(this.metric)
      })
      .finally(() => {
        this.loaderMetric = false
      })
    }
  }
}
</script>