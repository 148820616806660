<script>
import { Bar, mixins } from 'vue-chartjs'
import numeral from 'numeral'
import moment from 'moment'
export default {
  extends: Bar,
  props: {
    axeTypeY: {
      type: String,
      required: false,
      default: 'number'
    },
    numberLabel: {
      type: String,
      required: false,
      default: ''
    },
    numberRounded: {
      type: Boolean,
      required: false,
      default: false
    },
    axeTypeX: {
      type: String,
      required: false,
      default: 'date'
    },
    showAxeX: {
      type: Boolean,
      required: false,
      default: true
    },
    finalAsCompute: {
      type: Boolean,
      required: false,
      default: false
    },
    colors: {
      type: Array,
      required: false,
      default: () => (['#2962FF', '#5C6BC0', '#3F51B5', '#FFD600', '#FFEA00'])
    },
    labels: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  mixins: [
    mixins.reactiveProp
  ],
  mounted () {
    this.drawChart()
  },
  watch: {
    chartData () {
      this.drawChart()
    }
  },
  computed: {
    options () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        hover: {
          mode: 'index',
          intersect: true
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          enabled: false,
          custom: this.customTooltips,
          displayColors: true,
          backgroundColor: '#FFFFFF',
          titleFontColor: '#6B7C93',
          titleFontSize: 12,
          titleMarginBottom: 10,
          bodyFontColor: '#56A9FD',
          borderColor: '#D8E0EA',
          borderWidth: 1,
          xPadding: 20,
          yPadding: 10,
          callbacks: {
            title (items, data) {
              return moment(items[0].xLabel.split('T')[0]).format('MMM YYYY')
            },
            label: (item, data) => {
              let label = data.datasets[item.datasetIndex].label
              let value = item.yLabel
              if (this.axeTypeY === 'number') {
                numeral.localeData().delimiters.thousands = this.numberRounded ? '.' : ','
                numeral.localeData().delimiters.decimal = this.numberRounded ? ',' : '.'
                value = numeral(value).format(this.numberRounded ? '0,0' : '0,0.00')
              }
              if (this.numberLabel) {
                value = `${value} ${this.numberLabel}`
              }
              return {
                label: label,
                value: value
              }
            }
          }
        },
        scales: {
          yAxes: [{
            stacked: true,
            gridLines: {
              color: 'transparent',
              display: true,
              drawBorder: false,
              zeroLineColor: '#D8E0EA',
              zeroLineWidth: 1
            },
            ticks: {
              display: false
            }
          }],
          xAxes: [{
            stacked: true,
            barPercentage: 0.8,
            gridLines: {
              display: false,
              drawBorder: false
            },
            ticks: {
              display: false,
              fontSize: 12,
              fontColor: '#6B7C93',
              fontFamily: 'Open Sans',
              maxRotation: 0,
              maxTicksLimit: 2,
              callback (value) {
                return moment(value.split('T')[0]).format('MMM YYYY')
              }
            }
          }]
        }
      }
    }
  },
  methods: {
    drawDatasets () {
      this.chartData.datasets.forEach((dataset, i) => {
        if (this.finalAsCompute && i === this.chartData.datasets.length - 1) {
          this.chartData.datasets[i] = Object.assign({}, this.chartData.datasets[i], {
            type: 'line',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: this.colors[this.colors.length - 1],
            borderWidth: 1.5,
            pointStyle: 'circle',
            pointRadius: 3,
            pointHoverRadius: 3,
            pointBorderWidth: 0,
            pointBackgroundColor: this.colors[this.colors.length - 1],
            lineTension: 0,
            order: 1,
            label: this.labels[this.labels.length - 1] ? this.labels[this.labels.length - 1] : this.chartData.datasets[i].label
          })
        } else {
          this.chartData.datasets[i] = Object.assign({}, this.chartData.datasets[i], {
            backgroundColor: this.colors[i],
            hoverBackgroundColor: this.colors[i],
            order: this.finalAsCompute ? i + 2 : i + 1,
            label: this.labels[i] ? this.labels[i] : this.chartData.datasets[i].label
          })
        }
      })
    },
    drawChart () {
      this.drawDatasets()
      this.renderChart(this.chartData, this.options)
    },
    customTooltips (tooltip) {
      let tooltipEl = document.getElementById(`chartjs-tooltip__${this.chartId}`)
      if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.id = `chartjs-tooltip__${this.chartId}`
        tooltipEl.innerHTML = '<table></table>'
        this.$refs.canvas.parentNode.appendChild(tooltipEl)
      }
      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0
        return
      }
      if (tooltip.body) {
        const title = tooltip.title || []
        const lines = tooltip.body.map(tooltip => tooltip.lines[0])
        if (this.finalAsCompute) {
          lines.push(lines.splice(0, 1)[0])
          tooltip.labelColors.push(tooltip.labelColors.splice(0, 1)[0])
        }
        let html = '<thead>'
        title.forEach((title) => {
          html += '<tr>'
          html += `<th class="text-left grey-500--text font-weight-medium">${title}</th>`
          if (this.chartData.datasets[0].percent_change) {
            const percent = this.chartData.datasets[0].percent_change[tooltip.dataPoints[0].index]
            let textColor = ''
            if (percent > 0) {
              textColor = 'green--text text--darken-1'
            } else if (percent < 0) {
              textColor = 'red--text text--darken-1'
            } else {
              textColor = 'grey-500--text'
            }
            const percentNumeral = numeral(percent).format('0,0.00')
            html += `<th class="text-right font-weight-medium ${textColor}">${percentNumeral}%</th>`
          }
          html += `</tr>`
        })
        html += '</thead>'
        html += '<tbody>'
        lines.forEach((body, i) => {
          const colors = tooltip.labelColors[i]
          html += `
            <tr>
              <td class="py-0"><span class="chartjs-tooltip-key" style="background:${colors.backgroundColor}; border-color:${colors.borderColor}"></span> ${body.label}</td>
              <td class="py-0 text-right">${body.value}</td>
            </tr>
          `
        })
        html += '</tbody>'
        tooltipEl.querySelector('table').innerHTML = html
      }
      // display, position, and set styles for font
      tooltipEl.style.opacity = 1
      let idTooltip = document.getElementById(`chartjs-tooltip__${this.chartId}`)
      if (Math.round(tooltip.caretX / 4) >= 0) {
        tooltipEl.style.left = `${this.$refs.canvas.offsetLeft + tooltip.caretX - 35}px`
        idTooltip.style.setProperty('--left-pseudo', '25px')
      }
      if (this.$refs.canvas.width / 2 < Math.round(tooltip.caretX)) {
        tooltipEl.style.left = `${this.$refs.canvas.offsetLeft + tooltip.caretX - 205}px`
        idTooltip.style.setProperty('--left-pseudo', '195px')
      }
      tooltipEl.style.top = `${tooltip.height * -1 + 25}px`
      tooltipEl.style.fontFamily = tooltip._bodyFontFamily
      tooltipEl.style.fontSize = `${tooltip.bodyFontSize}px`
      tooltipEl.style.fontStyle = tooltip._bodyFontStyle
      tooltipEl.style.backgroundColor = tooltip.backgroundColor
      tooltipEl.style.titleFontColor = tooltip.titleFontColor
      tooltipEl.style.bodyFontColor = tooltip.bodyFontColor
      tooltipEl.style.borderColor = tooltip.borderColor
      tooltipEl.style.borderWidth = tooltip.borderWidth
    }
  }
}
</script>